import React from "react";
import { useLocation } from "react-router-dom";
import Footer3 from "./Footer3/Footer3";
import Footer1 from "./Footer1/Footer1";
import Footer2 from "./Footer2/Footer2";
function Footer() {
  const { pathname } = useLocation();

  if (["/profile", "/login", "/create-account"].includes(pathname)) {
    return <Footer3 />;
  } else if (pathname === "/contact-us") {
    return <Footer2 />;
  } else {
    return <Footer1 />;
  }
}

export default Footer;

import { useState } from "react";
import styles from "../BottlesSection/BottlesSection.module.css";

import { Col, Container, Row } from "react-bootstrap";
import menu from "../../../data/Menu.json";

import AppleCarrotZinger from "../../../assets/images/Menu/Fusion Coldpressed/Apple Carrot Zinger.png";
import AppleOJoy from "../../../assets/images/Menu/Fusion Coldpressed/Apple O'joy.png";
import BeetyCarrotApple from "../../../assets/images/Menu/Fusion Coldpressed/Beety Carrot Apple.png";
import CarrotBeetBliss from "../../../assets/images/Menu/Fusion Coldpressed/Carrot Beet Bliss.png";
import CucumberCooler from "../../../assets/images/Menu/Fusion Coldpressed/Cucumber Cooler.png";
import MelonyAppleMelody from "../../../assets/images/Menu/Fusion Coldpressed/Melony Apple Melody.png";
import OrangePineFunshine from "../../../assets/images/Menu/Fusion Coldpressed/Orange Pine Funshine.png";
import PineappleCarrotZinger from "../../../assets/images/Menu/Fusion Coldpressed/Pineapple Carrot Zinger.png";
import PomApplePom from "../../../assets/images/Menu/Fusion Coldpressed/Pom Apple Pom.png";
import RubyOrangeRefresher from "../../../assets/images/Menu/Fusion Coldpressed/Ruby Orange Refresher.png";
import GreenRecharge from "../../../assets/images/Menu/Fusion Coldpressed/Melony Green Recharge.png";
import AshHydration from "../../../assets/images/Menu/Fusion Coldpressed/Ash Hydration.png";
import TangyMuskyMelon from "../../../assets/images/Menu/Fusion Coldpressed/Tangy Musky Melon.png";
import PineapplePapaYaYa from "../../../assets/images/Menu/Fusion Coldpressed/Pineapple Papa Ya Ya.png";

let fusionColdPressedImages = {
  BeetyCarrotApple,
  OrangePineFunshine,
  CucumberCooler,
  AppleCarrotZinger,
  PomApplePom,
  PineappleCarrotZinger,
  MelonyAppleMelody,
  RubyOrangeRefresher,
  CarrotBeetBliss,
  AppleOJoy,
  GreenRecharge,
  PineapplePapaYaYa,
  AshHydration,
  TangyMuskyMelon,
};

const FusionColdPressed = () => {
  const [show, setShow] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null); // State to track selected item

  const handleItemClick = (id) => {
    setSelectedItem(id);
    setShow(!show);
  };

  return (
    <>
      {" "}
      <Container id="fusion-coldpressed" className={styles["main-div"]}>
        {" "}
        <Row lg={4} md={2} xs={2}>
          {" "}
          {menu["Fusion Cold Pressed"].map((item) => {
            // <div className={styles.subdiv}>

            return (
              <Col key={item.id} className={" text-center mb-md-0"}>
                {" "}
                <img
                  src={fusionColdPressedImages[item.id]}
                  className={styles["image-bottle"]}
                  onClick={() => handleItemClick(item.id)}
                  // Handle image click
                  alt={item.text}
                />{" "}
                <li
                  type="button"
                  // btn-small
                  onClick={() => handleItemClick(item.id)}
                  // Keep list item click
                  className={`$ {
                            styles["image-headingtop"]
                        }

                        `}
                >
                  {" "}
                  <h5 className={styles["bottle-heading"]}>
                    {" "}
                    {item.text}
                  </h5>{" "}
                  {show === true && selectedItem === item.id ? "" : ""}
                </li>{" "}
              </Col>
            );
            // </div>
          })}
        </Row>{" "}
      </Container>{" "}
      {/* Use ProductDetails component */}
      {/* <ProductDetails selectedItem={selectedItem} /> */}
    </>
  );
};

export default FusionColdPressed;

import React from 'react';
import PropTypes from 'prop-types';
import styles from './Alert.module.css'; 

const Alert = ({ message }) => {
  return (
    <div className={styles.alert}>
      <p>{message}</p>
    </div>
  );
};

Alert.propTypes = {
  message: PropTypes.string.isRequired, 
};

export default Alert;

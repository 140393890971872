import React from "react";
import Wave from "react-wavify";
import styles from "../Pure Joy Part/PureJoy.module.scss";
import "../../../Styles/variables.scss";

const PureJoyPart = () => {
  return (
    <>
      <div className={styles["parent"]}>
        <div className={styles["main_headings"]}>
        <h3 className={styles["heading1"]}>We deliver</h3>
        <h1 className={styles["heading2"]}>Pure JOY</h1>
        </div>
        <p className={styles["paralorem"]}>
          Forget Genies, we grant your wish for refreshment in a bottle right
          here. Experience the pure joy of fresh fruits packed with all the
          goodness you deserve. Our little secret ingredient - No added sugar!
        </p>
        <Wave
          fill="var(--wave-fill-Green)"
          paused={false}
          className={styles["waveUp"]}
          // style={{
          //   display: "flex",
          //   height: "54.7vh",
          // }}
          options={{
            height: 10,
            amplitude: 20,
            speed: 0.15,
            points: 3,
          }}
        />
        <Wave
          fill="var(--wave-fill-Green)"
          paused={false}
          className={styles["waveDown"]}
          // style={{
          //   display: "flex",
          //   height: "40px",
          //   transform: "rotate(180deg)",
          //   position: "absolute",
          //   bottom: "-30px",
          //   left: 0,
          //   right: 0,
          // }}
          options={{
            height: 10,

            amplitude: 20,
            speed: 0.15,
            points: 3,
          }}
        />
      </div>
      <h1 className={`${styles["heading"]} text-center mx-auto`}>
        Freshness this way
      </h1>
    </>
  );
};

export default PureJoyPart;

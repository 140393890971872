import React from 'react'

const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior:"instant"
        });
  return (
    <div>
      
    </div>
  )
}

export default scrollToTop

import { useState } from "react";
import styles from "../BottlesSection/BottlesSection.module.css";
import { Col, Container, Row } from "react-bootstrap";
import menu from "../../../data/Menu.json";
import AppleBananaShake from "../../../assets/images/Menu/Milkshakes/Apple Banana Shake.png";
import ClassicBananaShake from "../../../assets/images/Menu/Milkshakes/Classic Banana Shake.png";
import ClassicMangoShake from "../../../assets/images/Menu/Milkshakes/Classic Mango Shake.png";
import CreamyStrawberryShake from "../../../assets/images/Menu/Milkshakes/Creamy Strawberry Shake.png";
import BananaDatesShake from "../../../assets/images/Menu/Milkshakes/Date Banana Shake.png";
import DryfruitDreamShake from "../../../assets/images/Menu/Milkshakes/Dry Fruit Shake.png";
import PinoCashewShake from "../../../assets/images/Menu/Milkshakes/Pino Cashew Shake.png";
import StrawberryBananaShake from "../../../assets/images/Menu/Milkshakes/Strawberry Banana Shake.png";

// import ProductDetails from "../../ProductDetails/ProductDetails";

let MilkshakesImages = {
  AppleBananaShake,
  ClassicBananaShake,
  ClassicMangoShake,
  CreamyStrawberryShake,
  BananaDatesShake,
  DryfruitDreamShake,
  PinoCashewShake,
  StrawberryBananaShake,
};

const Milkshakes = () => {
  const [show, setShow] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null); // State to track selected item

  const handleItemClick = (id) => {
    setSelectedItem(id);
    setShow(!show);
  };

  return (
    <>
      <Container id="Milkshakes" className={styles["main-div"]}>
        <Row lg={4} md={2} xs={2}>
          {menu["Milkshakes"].map((item) => {
            // <div className={styles.subdiv}>
            return (
              <Col key={item.id} className={" text-center mb-md-0"}>
                <img
                  src={MilkshakesImages[item.id]}
                  className={styles["image-bottle"]}
                  onClick={() => handleItemClick(item.id)} // Handle image click
                  alt={item.text}
                />
                <li
                  type="button"
                  // btn-small
                  onClick={() => handleItemClick(item.id)} // Keep list item click
                  className={`${styles["image-headingtop"]} `}
                >
                  <h5 className={styles["bottle-heading"]}>{item.text}</h5>
                  {show === true && selectedItem === item.id ? "" : ""}
                </li>
              </Col>
            );
            // </div>
          })}
        </Row>
      </Container>
      {/* Use ProductDetails component */}
      {/* <ProductDetails selectedItem={selectedItem} /> */}
    </>
  );
};

export default Milkshakes;

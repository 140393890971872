
import React, { createContext, useState } from 'react';

export const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [state, setState] = useState({
    // Initialize your context state here if needed
  });

  const apiLinks = {
    submitEmail: 'https://happysips.net:2001/api/v1/subscription/subscribe',
    
  };

  return (
    <AppContext.Provider value={{ state, setState, apiLinks }}>
      {children}
    </AppContext.Provider>
  );
};

import React from "react";
import styles from "./OurStoryBody2.module.scss";
import Wave from "react-wavify";
import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

const OurStoryBody2 = () => {
  const aboutUsRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    if (location.hash === "#about-us") {
      aboutUsRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [location]);

  return (
    <div ref={aboutUsRef} id="about-us">
      {/* <center>
 <div className="rectangle text-center">
      <video src=''/>
      </div>
  </center> */}
      <div className={styles["story-body-1"]}>
        <div className={styles["story-body-1-main"]}>
          <p className={styles["para1"]}>
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididnut"
          </p>
          <p className={styles["para2"]}>-Nandan Andra, Founder</p>
        </div>

        <Wave
           fill="var(--wave-fill-Green)"
          paused={false}
          className={styles["wave-Up"]}
          // style={{
          //   display: "flex",
          //   height: "54.7vh",
          // }}
          options={{
            // height: 10,
            amplitude: 20,
            speed: 0.15,
            points: 3,
          }}
        />
        <Wave
           fill="var(--wave-fill-Green)"
          paused={false}
          className={styles["wave-Down"]}
          // style={{
          //   display: "flex",
          //   height: "40px",
          //   transform: "rotate(180deg)",
          //   position: "absolute",
          //   bottom: "-30px",
          //   left: 0,
          //   right: 0,
          // }}
          options={{
            // height: 10,

            amplitude: 20,
            speed: 0.15,
            points: 3,
          }}
        />
      </div>

      <div className={`container ${styles["story-body-2"]}`}>
        <h4 className={`text-center ${styles["header-text"]}`}>Our Team</h4>

        <div className={styles["main-box"]}>
          <div className={styles["box1"]}>
            {/* <img src="" alt="" /> */}
          </div>

          <div className={styles["box2"]}>
            {/* <img src="" alt="" /> */}
          </div>

          <div className={styles["box1"]}>
            {/* <img src="" alt="" /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurStoryBody2;

import React, { useEffect, useState } from 'react'
import hyderabadBanner from "../../../assets/images/Home/HyderabadJuicier Images/Hyderabad Banner.png"
import styles from "./HyderabadJuicierPart.module.css"

const HyderabadJuicierPart = () => {

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    // Clean up the event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
   <div className={styles['image-div']}>
   <img className={styles['hydImage-style']} src={hyderabadBanner} alt="HyderabadCity"/>
   </div>
   </>
  )
}

export default HyderabadJuicierPart

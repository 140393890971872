import { useState } from "react";
import styles from "../BottlesSection/BottlesSection.module.css";

import { Col, Container, Row } from "react-bootstrap";
import menu from "../../../data/Menu.json";
import ABCSuperchargerSmoothie from "../../../assets/images/Menu/Smoothies/ABC Supercharger Smoothie.png";
import AlmondyOrangeSmoothie from "../../../assets/images/Menu/Smoothies/Almondy Orange Smoothie.png";
import AppleCarrot from "../../../assets/images/Menu/Smoothies/Apple Carrot Smoothie.png";
import AppleCinnamonSmoothie from "../../../assets/images/Menu/Smoothies/Apple Cinnamon Smoothie.png";
import CucumberApple from "../../../assets/images/Menu/Smoothies/Cucumber Apple Smoothie.png";
import KiwiAppleSmoothie from "../../../assets/images/Menu/Smoothies/Kiwi Apple Smoothie.png";
import MangoDragonfruitSmoothie from "../../../assets/images/Menu/Smoothies/Mango Dragon Fruit Smoothie.png";
import MelonyBerrySmoothie from "../../../assets/images/Menu/Smoothies/Melony Berry Smoothie.png";
import PineappleTurmericSmoothie from "../../../assets/images/Menu/Smoothies/Pineapple Turmeric Smoothie.png";
import StrawberryBananaSmoothie from "../../../assets/images/Menu/Smoothies/Strawberry Banana Smoothie.png";
import WatermelonChiaSmoothie from "../../../assets/images/Menu/Smoothies/Watermelon Chia Smoothie.png";
import ProductDetails from "../../ProductDetails/ProductDetails";
let SmoothiesImages = {
  ABCSuperchargerSmoothie,
  KiwiAppleSmoothie,
  AlmondyOrangeSmoothie,
  AppleCarrot,
  AppleCinnamonSmoothie,
  CucumberApple,
  MangoDragonfruitSmoothie,
  MelonyBerrySmoothie,
  PineappleTurmericSmoothie,
  StrawberryBananaSmoothie,
  WatermelonChiaSmoothie,
};

const Smoothies = () => {
  const [show, setShow] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null); // State to track selected item

  const handleItemClick = (id) => {
    setSelectedItem(id);
    setShow(!show);
  };

  return (
    <>
      {" "}
      <Container id="Smoothies" className={styles["main-div"]}>
        {" "}
        <Row lg={4} md={2} xs={2}>
          {" "}
          {menu["Smoothies"].map((item) => {
            // <div className={styles.subdiv}>

            return (
              <Col key={item.id} className={" text-center mb-md-0"}>
                {" "}
                <img
                  src={SmoothiesImages[item.id]}
                  className={styles["image-bottle"]}
                  onClick={() => handleItemClick(item.id)}
                  // Handle image click
                  alt={item.text}
                />{" "}
                <li
                  type="button"
                  // btn-small
                  onClick={() => handleItemClick(item.id)}
                  // Keep list item click
                  className={`$ {
                            styles["image-headingtop"]
                        }

                        `}
                >
                  {" "}
                  <h5 className={styles["bottle-heading"]}> {item.text}</h5>{" "}
                  {show === true && selectedItem === item.id ? "" : ""}
                </li>{" "}
              </Col>
            );
            // </div>
          })}
        </Row>{" "}
      </Container>{" "}
      {/* Use ProductDetails component */}
      <ProductDetails selectedItem={selectedItem} />
    </>
  );
};

export default Smoothies;

import React from "react";
import Zomato from "../../../assets/images/WhereToBuy/Zomato Image.png";
import Swiggy from "../../../assets/images/WhereToBuy/Swiggy Image.png";
import styles from "../NeedSipOfJoy/NeedSipOfJoy.module.css";
import { Link } from "react-router-dom";
function NeedSipOfJoy() {
  return (
    <div className="text-center">
      <p className={`${styles["text1"]} mb-5 mt-5`}>Need a sip of joy?</p>
      <p className={`${styles["text2"]}  mt-3`}>Catch us on</p>
      <div className="d-flex  justify-content-center">
        <div className="text-center ">
          <img
            src={Zomato}
            className={styles["img_size"]}
            alt="Zomato Logo"
          ></img>
          <Link
            to="https://link.zomato.com/xqzv/rshare?id=7238440330563d64" target="_blank"
            className={`${styles["text3"]} ${styles["underline"]} mb-2 mt-2`}
          >
            Zomato/happysips
          </Link>
        </div>
        <div className={`${styles["img_marginleft"]} text-center mr-5`}>
          <img
            src={Swiggy}
            className={styles["img_size"]}
            alt="Swiggy Logo"
          ></img>
          <Link
            to="https://www.swiggy.com/direct/brand/484624?source=swiggy-direct&subSource=generic" target="_blank"
            className={`${styles["text3"]} ${styles["underline"]} mb-2 mt-2`}
          >
            Swiggy/happysips
          </Link>
        </div>
      </div>
      <p className={`${styles["text2"]} mb-5 mt-5`}>in Hyderabad.</p>
    </div>
  );
}

export default NeedSipOfJoy;

// import React, { useState } from "react";
// import axios from "axios";
// import Alert from "./Alert"; 
// import iconimage from "../../../assets/Logo/tickicon.png";
// import styles from "./EmailSubmitAck.module.css"; 

// const EmailSection = () => {
//   const [email, setEmail] = useState("");
//   const [submitted, setSubmitted] = useState(false);
//   const [loading, setLoading] = useState(false);
//   const [emailError, setEmailError] = useState(false);
//   const [serverDown, setServerDown] = useState(false);

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     setEmailError(false);
//     setServerDown(false);
//     if (!email) {
//       setEmailError(true);
//       // setTimeout(() => {
//       //   setEmailError(false);
//       // }, 5000);
//       return;
//     }

//     setLoading(true);

//     try {
//       const response = await axios.post(
//         "https://happysips.net:2001/api/v1/subscription/subscribe",
//         { email },
//         {
//           headers: {
//             "Content-Type": "application/json",
//           },
//         }
//       );

//       if (response.status === 201) {
//         setEmail("");
//         setSubmitted(true);
//         setTimeout(() => {
//           setSubmitted(false);
//         }, 5000);
//       } else {
//         setEmailError(true);
//         setTimeout(() => {
//           setEmailError(false);
//         }, 5000);
//       }
//     } catch (error) {
//       console.error("Error submitting form:", error);
//       setServerDown(true);
//       setTimeout(() => {
//         setServerDown(false);
//       }, 3000);
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handleInputFocus = () => {
//     setEmailError(false);
//   };

//   return (
//     <div className={styles.seconddiv}>
//       <h2>Exciting offers</h2>
//       <form onSubmit={handleSubmit}>
//         <div className="input-group mt-5">
//           <input
//             type="email"
//             className={`form-control ${styles.input1}`}
//             placeholder="Enter Your email Here"
//             aria-label="Recipient's username"
//             aria-describedby="basic-addon2"
//             value={email}
//             onChange={(e) => setEmail(e.target.value)}
//             onFocus={handleInputFocus}
//           />
//           <button
//             type="submit"
//             className={`input-group-text ${styles.submit}`}
//             id="basic-addon2"
//           >
//             SUBMIT
//           </button>
//         </div>
//       </form>
//       {submitted && (
//         <div className="submission-message">
//           <img
//             src={iconimage}
//             alt="icon"
//             className={styles["icon-image"]}
//           />
//           <span>Thank you for submitting!</span>
//         </div>
//       )}
//       {loading && (
//         <div className={styles.loader}>
//           <div className={styles["loader-text"]}>Loading...</div>
//         </div>
//       )}
//       {emailError && (
//         <Alert message="Please fill out the email address field." />
//       )}
//       {serverDown && (
//         <Alert message=" Server down. Please try again later" />
//       )}
//     </div>
//   );
// };

// export default EmailSection;

// import React, { useState, useContext } from "react";
// import axios from "axios";
// import Alert from "./Alert"; 
// import iconimage from "../../../assets/Logo/tickicon.png";
// import styles from "./EmailSubmitAck.module.css"; 

// // Create a context to hold the API link
// const ApiLinkContext = React.createContext();

// // Custom hook to access the API link from context
// const useApiLink = () => useContext(ApiLinkContext);

// const EmailSection = () => {
//   const apiLink = useApiLink(); // Access the API link from context

//   const [email, setEmail] = useState("");
//   const [submitted, setSubmitted] = useState(false);
//   const [loading, setLoading] = useState(false);
//   const [emailError, setEmailError] = useState(false);
//   const [serverDown, setServerDown] = useState(false);

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     setEmailError(false);
//     setServerDown(false);
//     if (!email) {
//       setEmailError(true);
//       // setTimeout(() => {
//       //   setEmailError(false);
//       // }, 5000);
//       return;
//     }

//     setLoading(true);

//     try {
//       const response = await axios.post(
//         apiLink, // Use the API link from context
//         { email },
//         {
//           headers: {
//             "Content-Type": "application/json",
//           },
//         }
//       );

//       if (response.status === 201) {
//         setEmail("");
//         setSubmitted(true);
//         setTimeout(() => {
//           setSubmitted(false);
//         }, 5000);
//       } else {
//         setEmailError(true);
//         setTimeout(() => {
//           setEmailError(false);
//         }, 5000);
//       }
//     } catch (error) {
//       console.error("Error submitting form:", error);
//       setServerDown(true);
//       setTimeout(() => {
//         setServerDown(false);
//       }, 3000);
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handleInputFocus = () => {
//     setEmailError(false);
//   };

//   return (
//     <div className={styles.seconddiv}>
//       <li id={styles.column1Header}>Exciting offers</li>
//       <div>
//       <form onSubmit={handleSubmit}>
//         <div className={`input-group ${styles["offer_div"]}`}>
//           <input
//             type="email"
//             className={`form-control ${styles["input1"]} ${styles["form-control"]}`}
//             placeholder="Enter Your email Here"
//             aria-label="Recipient's username"
//             aria-describedby="basic-addon2"
//             value={email}
//             onChange={(e) => setEmail(e.target.value)}
//             onFocus={handleInputFocus}
//           />
//           <button
//             type="submit"
//             className={`input-group-text ${styles.submit}`}
//             id="basic-addon2"
//           >
//             SUBMIT
//           </button>
//         </div>
//       </form>
//       {submitted && (
//         <div className="submission-message">
//           <img
//             src={iconimage}
//             alt="icon"
//             className={styles["icon-image"]}
//           />
//           <span>Thank you for submitting!</span>
//         </div>
//       )}
//       {loading && (
//         <div className={styles.loader}>
//           <div className={styles["loader-text"]}>Loading...</div>
//         </div>
//       )}
//       {emailError && (
//         <Alert message="Please fill out the email address field." />
//       )}
//       {serverDown && (
//         <Alert message=" Server down. Please try again later" />
//       )}
//     </div>
//     </div>
//   );
// };

// // Export the component wrapped with the context provider
// const EmailSubmitAckWithContext = () => (
//   <ApiLinkContext.Provider value="https://happysips.net:2001/api/v1/subscription/subscribe">
//     <EmailSection />
//   </ApiLinkContext.Provider>
// );

// export default EmailSubmitAckWithContext;

// src/components/EmailSubmitAck/EmailSubmitAck.js
import React, { useState, useContext } from 'react';
import axios from 'axios';
import Alert from './Alert'; 
import iconimage from '../../../assets/Logo/tickicon.png';
import styles from './EmailSubmitAck.module.css'; 
import { AppContext } from '../../../context/AppContext';

const EmailSubmitAck = () => {
  const { apiLinks } = useContext(AppContext);
  const apiLink = apiLinks.submitEmail; // Access the API link from context

  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [serverDown, setServerDown] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setEmailError(false);
    setServerDown(false);
    if (!email) {
      setEmailError(true);
      return;
    }

    setLoading(true);

    try {
      const response = await axios.post(
        apiLink, // Use the API link from context
        { email },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status === 201) {
        setEmail('');
        setSubmitted(true);
        setTimeout(() => {
          setSubmitted(false);
        }, 5000);
      } else {
        setEmailError(true);
        setTimeout(() => {
          setEmailError(false);
        }, 5000);
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      setServerDown(true);
      setTimeout(() => {
        setServerDown(false);
      }, 3000);
    } finally {
      setLoading(false);
    }
  };

  const handleInputFocus = () => {
    setEmailError(false);
  };

  return (
    <div className={styles.seconddiv}>
      <li id={styles.column1Header}>Exciting offers</li>
      <div>
        <form onSubmit={handleSubmit}>
          <div className={`input-group ${styles['offer_div']}`}>
            <input
              type="email"
              className={`form-control ${styles['input1']} ${styles['form-control']}`}
              placeholder="Enter Your email Here"
              aria-label="Recipient's username"
              aria-describedby="basic-addon2"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              onFocus={handleInputFocus}
            />
            <button
              type="submit"
              className={`input-group-text ${styles.submit}`}
              id="basic-addon2"
            >
              SUBMIT
            </button>
          </div>
        </form>
        {submitted && (
          <div className="submission-message">
            <img
              src={iconimage}
              alt="icon"
              className={styles['icon-image']}
            />
            <span>Thank you for submitting!</span>
          </div>
        )}
        {loading && (
          <div className={styles.loader}>
            <div className={styles['loader-text']}>Loading...</div>
          </div>
        )}
        {emailError && (
          <Alert message="Please fill out the email address field." />
        )}
        {serverDown && (
          <Alert message="Server down. Please try again later." />
        )}
      </div>
    </div>
  );
};

export default EmailSubmitAck;

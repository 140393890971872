import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Wave from "react-wavify";
import styles from "./MenuNavbar.module.scss";
import { FiChevronRight, FiChevronLeft } from "react-icons/fi";

//To find the current screen size
const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({ width: window.innerWidth, height: window.innerHeight });
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return windowSize;
};

const MenuNavbar = ({ handleNavItemClick }) => {
  const { width } = useWindowSize();

  // NavItems in array
  const navItems = [
    "Classic Coldpressed",
    "Fusion Coldpressed",
    "Smoothies",
    "Milkshakes",
    "Cut Fruit Bowls",
    "Smoothie Bowls",
  ];
  // MenuNavbar arrow navigation state
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 3;

  const handleMenuNavPrevClick = () => {
    setCurrentPage((prevPage) =>
      prevPage === 0 ? navItems.length / itemsPerPage - 1 : prevPage - 1
    );
  };

  const handleMenuNavNextClick = () => {
    setCurrentPage(
      (prevPage) => (prevPage + 1) % Math.ceil(navItems.length / itemsPerPage)
    );
  };

  const currentNavItems = navItems.slice(
    currentPage * itemsPerPage,
    currentPage * itemsPerPage + itemsPerPage
  );

  return (
    <>
      <div>
        {/* <div className={`${styles["body-content-1"]} w-100`}> */}
        {/* mobile design */}
        {width < 768 ? (
          <div className={`${styles["body-content-1"]} d-flex w-100`}>
            <button
              className={styles["menuBar-btn"]}
              onClick={handleMenuNavPrevClick}
            >
              <FiChevronLeft />
            </button>
            {currentNavItems.map((item, index) => {
              const itemId = item.replace(/\s+/g, "");
              return (
                <div key={item}>
                  <Link
                    to={`/menu#${item.toLowerCase().replace(/ /g, "-")}`}
                    className={styles["tab"]}
                    onClick={() =>
                      handleNavItemClick(navItems.indexOf(item), itemId)
                    }
                  >
                    {item}
                  </Link>
                </div>
              );
            })}
            <button
              className={styles["menuBar-btn"]}
              onClick={handleMenuNavNextClick}
            >
              <FiChevronRight />
            </button>
          </div>
        ) : (
          /* desktop design */
          <div className={`${styles["body-content-1"]} d-flex w-100`}>
            {navItems.map((item, index) => {
              const itemId = item.replace(/\s+/g, "");
              return (
                <div key={item}>
                  <Link
                    to={`/menu#${item.toLowerCase().replace(/ /g, "-")}`}
                    className={styles["tab"]}
                    onClick={() =>
                      handleNavItemClick(navItems.indexOf(item), itemId)
                    }
                  >
                    {item}
                  </Link>
                </div>
              );
            })}
          </div>
        )}
        <div></div>
      </div>
    </>
  );
};

export default MenuNavbar;

import { BrowserRouter, Route, Routes /*,Link*/ } from "react-router-dom";
import Navbar from "./components/Navbar/NavbarMain";
import HomePage from "./containers/HomePage/HomePage";
import OurStoryPage from "./containers/OurStory Page/OurStoryPage";
import "../src/assets/Fonts/Proxima Nova Alt Bold.otf";
import WhereToBuyPage from "./containers/WhereToBuy Page/WhereToBuyPage";
import ContactUs from "./containers/ContactUs/ContactUs";
import AdminCompose from "./containers/AdminCompose/FormSection/FormSection";
import Footer from "./components/Footer/Footer";
import "./index.scss";
import ProductDetails from "./containers/ProductDetails/ProductDetails";
import Menu from "./containers/MenuPage/MenuPage";
import NutritionPage from "./containers/NutritionPage/NutritionPage";

function App() {
  return (
    <BrowserRouter>
      <Navbar />
      <>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/menu" element={<Menu />} />
          <Route path="/where-to-buy" element={<WhereToBuyPage />} />
          <Route path="/our-story" element={<OurStoryPage />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/admin-compose" element={<AdminCompose />} />
          <Route path="/Product-details" element={<ProductDetails />} />
          <Route path="/nutrition-page" element={<NutritionPage />} />
        </Routes>

        <Footer />
      </>
    </BrowserRouter>
  );
}

export default App;

import React, { useState } from "react";
import HeaderSection from "./MenuBody1/HeaderSection";
import MenuNavbar from "./MenuBody1/MenuNavbar";
import CarouselSection from "./MenuBody1/CarouselSection";
import ClassicColdPressedAll from "./ClassicColdPressed/ClassicColdPressed";
import FusionColdPressedAll from "./FusionColdPressed/FusionColdPressed";
import SmoothiesAll from "./Smoothies/Smoothies";
import MilkshakesAll from "./Milkshakes/Milkshakes";
import CutFruitBowlsAll from "./Cut Fruit Bowls/CutFruitBowls";
import SmoothieBowlsAll from "./SmoothieBowls/SmoothieBowls";
import NutritionPart from "../WhereToBuy Page/NutritionPart/NutritionPart";
import ResponsiveWave from "./MenuBody1/ResponsiveWave";

const MenuBody1 = () => {
  const [selectedNavItem, setSelectedNavItem] = useState("ClassicColdpressed");
  const [carouselImageIndex, setCarouselImageIndex] = useState(0);

  const handleNavItemClick = (index, selectedNavItem) => {
    setCarouselImageIndex(index);
    setSelectedNavItem(selectedNavItem);
  };

  return (
    <>
      <HeaderSection />
      <MenuNavbar handleNavItemClick={handleNavItemClick} />
      <ResponsiveWave />
      <CarouselSection
        selectedNavItem={selectedNavItem}
        setSelectedNavItem={setSelectedNavItem}
        carouselImageIndex={carouselImageIndex}
        setCarouselImageIndex={setCarouselImageIndex}
      />
      {selectedNavItem === "ClassicColdpressed" && <ClassicColdPressedAll />}
      {selectedNavItem === "FusionColdpressed" && <FusionColdPressedAll />}
      {selectedNavItem === "Smoothies" && <SmoothiesAll />}
      {selectedNavItem === "Milkshakes" && <MilkshakesAll />}
      {selectedNavItem === "CutFruitBowls" && <CutFruitBowlsAll />}
      {selectedNavItem === "SmoothieBowls" && <SmoothieBowlsAll />}
      <NutritionPart />
    </>
  );
};

export default MenuBody1;

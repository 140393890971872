import { useState } from "react";
import styles from "../BottlesSection/BottlesSection.module.css";
import { Col, Container, Row } from "react-bootstrap";
import menu from "../../../data/Menu.json";
import BlushBowl from "../../../assets/images/Menu/Smoothie Bowls/Blush Bowl.png";
import TropicalBowl from "../../../assets/images/Menu/Smoothie Bowls/Tropical Bowl.png";
import MarigoldBowl from "../../../assets/images/Menu/Smoothie Bowls/Marigold Bowl.png";
import StrawberryBowl from "../../../assets/images/Menu/Smoothie Bowls/Strawberry Bowl.png";
import ColadaBowl from "../../../assets/images/Menu/Smoothie Bowls/Colado Bowl.png";
import ProductDetails from "../../ProductDetails/ProductDetails";

let SmoothieBowlsImages = {
  BlushBowl,
  TropicalBowl,
  MarigoldBowl,
  ColadaBowl,
  StrawberryBowl,
};

const SmoothieBowls = () => {
  const [show, setShow] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null); // State to track selected item

  const handleItemClick = (id) => {
    setSelectedItem(id);
    setShow(!show);
  };

  return (
    <>
      <Container id="SmoothieBowls" className={styles["smoothieBowls-main"]}>
        <Row lg={3} md={2} xs={2}>
          {menu["Smoothie Bowls"].map((item) => {
            // <div className={styles.subdiv}>
            return (
              <Col key={item.id} className={" text-center mb-md-0"}>
                <img
                  src={SmoothieBowlsImages[item.id]}
                  className={styles["Bowl-image-styles"]}
                  // style={{ height: "100%", width: "100%" }}
                  onClick={() => handleItemClick(item.id)} // Handle image click
                  alt={item.text}
                />
                <li
                  type="button"
                  // btn-small
                  onClick={() => handleItemClick(item.id)} // Keep list item click
                  // className={`${styles["image-headingtop"]} `}
                >
                  <h5 className={styles["bottle-heading"]}>{item.text}</h5>
                  {show === true && selectedItem === item.id ? "" : ""}
                </li>
              </Col>
            );
            // </div>
          })}
        </Row>
      </Container>
      {/* Use ProductDetails component */}
      <ProductDetails selectedItem={selectedItem} />
    </>
  );
};

export default SmoothieBowls;

import { Position } from "@syncfusion/ej2-base";
import React, { useState, useEffect } from "react";
import Wave from "react-wavify";

const ResponsiveWave = () => {
  const [waveOptions, setWaveOptions] = useState({
    height: 20,
    amplitude: 10,
    points: 1,
  });

  //update the wave options based on the screen size
  const updateWaveOptions = () => {
    const width = window.innerWidth;
    let newHeight, newAmplitude, newPoints;

    //need to optimize - to avoid the repeation
    if (width >= 320 && width <= 480) {
      newHeight = 105;
      newAmplitude = 15;
      newPoints = 1;
    } else if (width >= 481 && width <= 576) {
      newHeight = 105;
      newAmplitude = 15;
      newPoints = 2;
    } else if (width >= 577 && width <= 768) {
      newHeight = 105;
      newAmplitude = 15;
      newPoints = 2;
    } else {
      newHeight = 93;
      newAmplitude = 20;
      newPoints = 3; //default for larger screens
    }
    console.log(
      `setting wave option: height=${newHeight},ampli=${newAmplitude},points=${newPoints}`
    );
    setWaveOptions({
      height: newHeight,
      amplitude: newAmplitude,
      points: newPoints,
    });
  };

  useEffect(() => {
    updateWaveOptions();
    window.addEventListener("resize", updateWaveOptions);
    return () => window.removeEventListener("resize", updateWaveOptions);
  }, []);

  return (
    <>
      <Wave
        fill="var(--wave-fill-Green)"
        paused={false}
        style={{ marginTop: "-7vh" }}
        options={{
          height: waveOptions.height,
          amplitude: waveOptions.amplitude,
          speed: 0.2,
          points: waveOptions.points,
        }}
      />
      <Wave
        fill="var(--wave-fill-Green)"
        paused={false}
        style={{
          transform: "rotate(180deg)",
          zIndex: "1",
          // marginTop: "-1vh",
        }}
        options={{
          height: waveOptions.height + 10,
          amplitude: waveOptions.amplitude,
          speed: 0.2,
          points: waveOptions.points,
        }}
      />
    </>
  );
};

export default ResponsiveWave;

const ProductDetailsData = [
  {
    id: 1,
    about:
      "loremLorem ipsum dolor sit amet consectetur adipisicing elit.Aperiam obcaecati, mollitia nobis maxime beatae eveniet quasi iusto ipsam, ducimus earum ab explicabo nostrum rem ut modi soluta veritatis. Aspernatur, qui.!",
    ingredients: "2 handpicked pineapple in every 300ml",
    nutrition: {
      Energy: "45 Kcal",
      Fat: 1.4,
      Carbohydrates: 5.8,
      Fibre: 2.4,
      Protien: 14,
      Salt: 0.4,
      Vitamin: 4.5,
      Other: 2.3,
    },
  },
  {
    id: 2,
    about:
      "loremLorem ipsum dolor sit amet consectetur adipisicing elit.Aperiam obcaecati, mollitia nobis maxime beatae eveniet quasi iusto ipsam, ducimus earum ab explicabo nostrum rem ut modi soluta veritatis. Aspernatur, qui.!",
    ingredients: "2 handpicked pomegranates in every 300ml",
    nutrition: {
      Energy: "45 Kcal",
      Fat: 1.4,
      Carbohydrates: 5.8,
      Fibre: 2.4,
      Protien: 14,
      Salt: 0.4,
      Vitamin: 4.5,
      Other: 2.3,
    },
  },
  {
    id: 3,
    about:
      "loremLorem ipsum dolor sit amet consectetur adipisicing elit.Aperiam obcaecati, mollitia nobis maxime beatae eveniet quasi iusto ipsam, ducimus earum ab explicabo nostrum rem ut modi soluta veritatis. Aspernatur, qui.!",
    ingredients: "2 handpicked muskmelon in every 300ml",
    nutrition: {
      Energy: "45 Kcal",
      Fat: 1.4,
      Carbohydrates: 5.8,
      Fibre: 2.4,
      Protien: 14,
      Salt: 0.4,
      Vitamin: 4.5,
      Other: 2.3,
    },
  },
  {
    id: 4,
    about:
      "loremLorem ipsum dolor sit amet consectetur adipisicing elit.Aperiam obcaecati, mollitia nobis maxime beatae eveniet quasi iusto ipsam, ducimus earum ab explicabo nostrum rem ut modi soluta veritatis. Aspernatur, qui.!",
    ingredients: "2 handpicked watermelon in every 300ml",
    nutrition: {
      Energy: "45 Kcal",
      Fat: 1.4,
      Carbohydrates: 5.8,
      Fibre: 2.4,
      Protien: 14,
      Salt: 0.4,
      Vitamin: 4.5,
      Other: 2.3,
    },
  },
];

export { ProductDetailsData };

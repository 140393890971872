import React from "react";
import NutritionAndWave from "./NutritionAndWave/NutritionAndWave.js";
import OurPhilosophy from "./OurPhilosophy/OurPhilosophy.js";

const NutritionPage = () => {
  return (
    <div>
      <NutritionAndWave />
      <OurPhilosophy />
    </div>
  );
};

export default NutritionPage;

import React from "react";
import Wave from "react-wavify";
import styles from "./ContactUs.module.scss";
// import Footer3 from "../../components/Footer/Footer3/Footer3"
import Watermelon from "../../assets/images/ContactUs/watermelon.png";
import Image from "../../assets/images/ContactUs/Image.png";
import Banana from "../../assets/images/ContactUs/banana1.png";
import Mail from "../../assets/images/ContactUs/msg.png";

function ContactUs() {
  return (
    <div className={styles.ContactUs}>
      <h3 className={styles.contact}> wanna contact us? </h3>

      <div className={styles.watermelon}>
        <div className="d-flex">
          <div className="text-center">
            <p className={styles.ears}>We're all ears</p>
            <p className={styles.buds}>and taste buds</p>
          </div>
          <img
            src={Image}
            alt="img"
            className={styles.inlineImg1}
          />
        </div>
        <img
          src={Watermelon}
          alt="img"
          className={styles.inlineImg2}
        />
      </div>

      <div className={styles["parent"]}>
        <p className={styles["paragraph"]}>
          Whether you've stummbled upon a fascinating new cirtus variety or
          simply have a question about our delicious drinks, we'd be thrilled to
          connect! Feel free to give us a ring. or send us a message - We're
          always happy to hear from you.
        </p>
        <Wave
          fill="var(--wave-fill-Green)"
          // fill={getComputedStyle(document.documentElement)
          //   .getPropertyValue("--wave-fill")
          //   .trim()}
          paused={false}
          // className={styles.wave}
        />
        <Wave
          fill="var(--wave-fill-Green)"
          // fill={getComputedStyle(document.documentElement)
          //   .getPropertyValue("--wave-fill")
          //   .trim()}
          paused={false}
          className={styles["waveDown"]}
          // className={styles.waveInverted}
        />
      </div>

      <div className="mt-5">
        {" "}
        <p className={styles.reach_us1} style={{ marginTop: "130px" }}>
          Here are some ways to reach us
        </p>
      </div>

      <div className="text-center">
        <img src={Banana} className={styles["img1"]} alt="Banana" />
        <p className={styles.reach_us1}>Banana Phone: 91XXXXXX15</p>
      </div>
      <div className="text-center">
        <p className={styles.reach_us2}>Drop us a line: email@happisips.net</p>
        <img src={Mail} className={styles["img2"]} alt="Mail" />
      </div>
      <div className="text-center">
        <p className={styles.note}>
          No carrier pigeon or tree-slice mailboxes necessary ( although the
          creativity is appreciated!) We look forward to getting in touch!{" "}
        </p>
      </div>
    </div>
    // </div>
  );
}

export default ContactUs;

import { useState } from "react";
import styles from "../BottlesSection/BottlesSection.module.css";
import { Col, Container, Row } from "react-bootstrap";
import menu from "../../../data/Menu.json";
import Pineapple from "../../../assets/images/Menu/Classic Coldpressed/Just Pineapple.png";
import Watermelon from "../../../assets/images/Menu/Classic Coldpressed/Just Watermelon.png";
import Orange from "../../../assets/images/Menu/Classic Coldpressed/Just Orange.png";
import Apple from "../../../assets/images/Menu/Classic Coldpressed/Just Apple.png";
import Muskmelon from "../../../assets/images/Menu/Classic Coldpressed/Just Muskmelon.png";
import Pomegrenate from "../../../assets/images/Menu/Classic Coldpressed/Just Pomegrenate.png";
import ProductDetails from "../../ProductDetails/ProductDetails";

let classicColdPressedImages = {
  Pineapple,
  Watermelon,
  Orange,
  Apple,
  Muskmelon,
  Pomegrenate,
};

const ClassicColdPressed = () => {
  // const classicRef = useRef(null);
  // const location = useLocation();

  // useEffect(() => {
  //   if (location.hash === "#classic-coldpressed") {
  //     classicRef.current.scrollIntoView({ behaviour: "smooth" });
  //   }
  // }, [location]);

  const [show, setShow] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null); // State to track selected item

  const handleItemClick = (id) => {
    setSelectedItem(id);
    setShow(!show);
  };

  return (
    <>
      <Container className={styles["main-div"]}>
        <Row lg={4} md={2} xs={2}>
          {menu["Classic Cold Pressed Juices"].map((item) => {
            // <div className={styles.subdiv}>
            return (
              <Col key={item.id} className={" text-center mb-md-0"}>
                <img
                  src={classicColdPressedImages[item.id]}
                  className={styles["image-bottle"]}
                  onClick={() => handleItemClick(item.id)} // Handle image click
                  alt={item.text}
                />
                <li
                  type="button"
                  // btn-small
                  onClick={() => handleItemClick(item.id)} // Keep list item click
                  className={`${styles["image-headingtop"]} `}
                >
                  <h5
                    // id="classic-coldpressed"
                    className={styles["bottle-heading"]}
                  >
                    {item.text}
                  </h5>
                  {show === true && selectedItem === item.id ? "" : ""}
                </li>
              </Col>
            );
            // </div>
          })}
        </Row>
      </Container>
      {/* Use ProductDetails component */}
      <ProductDetails selectedItem={selectedItem} />
    </>
  );
};

export default ClassicColdPressed;

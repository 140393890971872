import { useState } from "react";
import styles from "../BottlesSection/BottlesSection.module.css";
import { Col, Container, Row } from "react-bootstrap";
import menu from "../../../data/Menu.json";

import PapayaFruitBowl from "../../../assets/images/Menu/Cut Fruit Bowls/Papaya Bowl.png";

// import PapayaFruitBowl from "../../../assets/images/Menu/Cut Fruit Bowls/Papaya bowl.png"
import WatermelonFruitBowl from "../../../assets/images/Menu/Cut Fruit Bowls/Watermelon Bowl.png";
import PineappleFruitBowl from "../../../assets/images/Menu/Cut Fruit Bowls/Pineapple Bowl.png";
import MuskmelonFruitBowl from "../../../assets/images/Menu/Cut Fruit Bowls/Muskmelon Bowl.png";
import IslandFruitBowl from "../../../assets/images/Menu/Cut Fruit Bowls/Island Bowl.png";
import AllCutFruitBowl from "../../../assets/images/Menu/Cut Fruit Bowls/All Cut Bowl.png";
import ProductDetails from "../../ProductDetails/ProductDetails";

let CutFruitBowlsImages = {
  PapayaFruitBowl,
  WatermelonFruitBowl,
  PineappleFruitBowl,
  MuskmelonFruitBowl,
  IslandFruitBowl,
  AllCutFruitBowl,
};

const CutFruitBowls = () => {
  const [show, setShow] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null); // State to track selected item

  const handleItemClick = (id) => {
    setSelectedItem(id);
    setShow(!show);
  };

  return (
    <>
      <Container id="CutFruitBowls" className={styles["main-div"]}>
        <Row lg={3} md={2} xs={2}>
          {menu["Cut Fruit Bowls"].map((item) => {
            // <div className={styles.subdiv}>
            return (
              <Col key={item.id} className={" text-center mb-md-0"}>
                <img
                  src={CutFruitBowlsImages[item.id]}
                  className={styles["Bowl-image-styles"]}
                  // style={{
                  //   width:"100%",
                  //   height:"100%"
                  // }}
                  onClick={() => handleItemClick(item.id)} // Handle image click
                  alt={item.text}
                />
                <li
                  type="button"
                  // btn-small
                  onClick={() => handleItemClick(item.id)} // Keep list item click
                  className={`${styles["image-headingtop"]} `}
                >
                  <h5 className={styles["bottle-heading"]}>{item.text}</h5>
                  {show === true && selectedItem === item.id ? "" : ""}
                </li>
              </Col>
            );
            // </div>
          })}
        </Row>
      </Container>
      {/* Use ProductDetails component */}
      <ProductDetails selectedItem={selectedItem} />
    </>
  );
};

export default CutFruitBowls;

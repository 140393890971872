import React from "react";
import OurStoryBannerImg from "../../../../src/assets/images/OurstoryPage Images/Our Story Banner art.png";
import styles from "./OurStoryBody1.module.css";
import { TbPlayerPlayFilled } from "react-icons/tb";
const OurStoryBody1 = () => {
  return (
    <div>
      <img
        className={styles["BannerImg"]}
        src={OurStoryBannerImg}
        alt="Fresh Juice bottle"
      />
      <p className={styles["para"]}>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorum, optio
        consectetur labore culpa mollitia quibusdam non amet tempore, nihil
        omnis error alias architecto exercitationem! optio consectetur labore
        culpa mollitia quibusdam. Lorem ipsum dolor sit amet consectetur
        adipisicing elit.Lorem ipsum dolor consectetur sit  amet  
      </p>

      <div className={`${styles["rectangle"]} mx-auto`}>
        {/* <video src="" /> */}

        <button className="btn" type="button" mx-auto>
          <TbPlayerPlayFilled className={styles["Size"]} />
        </button>
      </div>
    </div>
  );
};

export default OurStoryBody1;

import React from "react";
import { FaFacebookSquare, FaInstagram } from "react-icons/fa";
import Wave from "react-wavify";
import styles from "./Footer1.module.scss";
import logoimg from "../../../assets/Logo/Happy sips logo fresh Alt 2 mono.png";
import EmailSubmitAck from "../EmailSubmitAck/EmailSubmitAck";
// import Alert from "./Alert";
import scrollToTop from "../scrollToTop";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

const Footer1 = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className={styles.footerMain}>
        <div className="d-flex justify-content-around ">
          <div className={styles.firstmaindiv}>
            <div className={styles.firstdiv}>
              <img
                src={logoimg}
                className={styles.image}
                alt="Happy sips Logo1"
              />
            </div>
            <div className={styles.firstsecond}>
              <li id={styles.column1Header}>Happiest sips</li>
              <li>Dryfruit Smoothy</li>
              <li>ABC Smoothy</li>
              <li>PinappleOrange Fusion</li>
              <li>Dryfruit Smoothy</li>
              <li>Dryfruit Smoothy</li>
              <li>Dryfruit Smoothy</li>
              <li>Dryfruit Smoothy</li>
            </div>
          </div>

          <div className={styles.secondmaindiv}>
            <div className={styles.secondfirst}>
              <li id={styles.column1Header}>Company</li>

              <li
                onClick={() => {
                  navigate("/our-story");
                  scrollToTop();
                }}
                className={styles["nav_Links"]}
              >
                Our Story
              </li>
              <li
                onClick={() => {
                  navigate("/nutrition-page");
                  scrollToTop();
                }}
                className={styles["nav_Links"]}
              >
                Nutrition
              </li>
              <li
                onClick={() => {
                  navigate("/blog");
                  scrollToTop();
                }}
              >
                <Link to="/blog" smooth={true} className={styles["nav_Links"]}>
                  Blog
                </Link>
              </li>
            </div>
            <EmailSubmitAck />
          </div>
        </div>
      </div>

      <div className={styles.thirdmaindiv}>
        <div className={styles.thirdfirstdiv}>
          <p className={styles["footer_Links"]}>Privacy policy</p>
          <p className={styles["footer_Links"]}>Terms of use</p>
          <p
            onClick={() => {
              navigate("/contact-us");
              scrollToTop();
            }}
            className={styles["footer_Links"]}
          >
            Contact Us
          </p>
          <p className="">
            <FaFacebookSquare style={{ fontSize: "3vw" }} />
          </p>
          <p className="">
            <FaInstagram style={{ fontSize: "3vw", marginLeft: "1vw" }} />
          </p>
        </div>

        <div className={styles.thirdseconddiv}>
          <p className={styles.para3}>
            @2024 Happy Sips Limited. All rights reserved. Happy Sips and the
            fruit logo are registered trsdemarks of Happy Sips Limited.
          </p>
        </div>
      </div>

      <Wave
        fill="#273d07"
        paused={false}
        style={{
          display: "flex",
          height: "22vh",
        }}
        options={{
          height: -11,
          amplitude: 23,
          speed: 0.15,
          points: 3,
        }}
      />
    </>
  );
};

export default Footer1;

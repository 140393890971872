import React, { useState, useEffect } from "react";
import Wave from "react-wavify";
import styles from "./WavePart.module.scss";
import fruit1 from "../../../assets/images/WhereToBuy/Fruit1.png";
import fruit2 from "../../../assets/images/WhereToBuy/Fruit2.png";
import fruit37 from "../../../assets/images/WhereToBuy/Fruit37.png";

function useResponsiveWaveSettings() {
  const [settings, setSettings] = useState({
    points: 3,
    amplitude: 15,
    speed: 0.15,
  });

  useEffect(() => {
    const updateSettings = () => {
      const width = window.innerWidth;
      let newSettings = {};
      if (width <= 320) {
        newSettings = { points: 2, amplitude: 5, speed: 0.15 };
      } else if (width <= 480) {
        newSettings = { points: 2, amplitude: 10, speed: 0.15 };
      } else if (width <= 576) {
        newSettings = { points: 2, amplitude: 12, speed: 0.15 };
      } else if (width <= 768) {
        newSettings = { points: 2, amplitude: 15, speed: 0.15 };
      } else {
        newSettings = { points: 3, amplitude: 10, speed: 0.15 };
      }
      setSettings(newSettings);
    };

    window.addEventListener("resize", updateSettings);
    updateSettings();

    return () => window.removeEventListener("resize", updateSettings);
  }, []);

  return settings;
}

function WavePart() {
  // const { points, amplitude, speed } = useResponsiveWaveSettings();

  return (
    <div className={styles["parent"]}>
      <div className={`${styles["images-container"]} mb-2`}>
        <div className={styles["images-container1"]}>
          <img src={fruit1} alt="Fruit 1" className={styles["image"]} />
        </div>
        <div className={styles["images-container2"]}>
          <img src={fruit2} alt="Fruit 2" className={styles["image"]} />
        </div>
        <div className={styles["images-container3"]}>
          <img src={fruit37} alt="Fruit 3" className={styles["image"]} />
        </div>
      </div>

      <Wave
        fill="var(--wave-fill-Green)"
        paused={false}
        options={{
          amplitude: 15,
          points: 3,
          height: 46,
          speed: 0.15,
        }}
      />
      <Wave
        fill="var(--wave-fill-Green)"
        paused={false}
        className={styles["waveDown"]}
        options={{
          amplitude: 15,
          points: 3,
          speed: 0.15,
        }}
      />

      <p className={styles["paragraph"]}>
        <span>
          No treasure map required... just a thirst for happiness and a click
          away from pure bliss.{" "}
        </span>
        <br />
        <br />
        <p className={styles["para"]}>Happy sipping, Hyderabad!</p>
      </p>
    </div>
  );
}

export default WavePart;

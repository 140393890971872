import React, { useState } from "react";
import styles from "./BlogAndOurStoryImg.module.css";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { FreshnessPartBlogOurStoryData } from "../../../data/FreshnessThisWay";
import scrollToTop from "../../../components/Footer/scrollToTop";

const BlogAndOurStoryImg = () => {
  const navigate = useNavigate();
  return (
    <>
      <center>
        <a href="/menu">
          <button type="button" className={styles.ButtonMore}>
            View All
          </button>
        </a>
      </center>

      {/* Render blog/story section */}
      <div className={styles["blog-story-main"]}>
        <Row xs={2} md={2} sm={2} style={{marginLeft:"0px", marginRight:"0px"}}>
          {FreshnessPartBlogOurStoryData.map(({ img, text, id, link }) => (
            <Col key={id} className="text-center mb-4 mb-md-0">
              <img src={img} className={`${styles["blog-story-image"]} mb-4`}  alt="blog story image"/>

              <h5
                onClick={() => {
                  navigate(link);
                  scrollToTop();
                }}
                className={`${styles["image-heading"]} mx-auto`}
              >
                {text}
              </h5>
            </Col>
          ))}
        </Row>
      </div>
    </>
  );
};

export default BlogAndOurStoryImg;

import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import styles from "./JoyofFreshness.module.css";
import { useNavigate } from "react-router-dom";
import HeroBanner from "../../../assets/images/Home/JoyOfFreshness images/Hero Banner element.png";
import HeroBannerMobile from "../../../assets/images/Home/JoyOfFreshness images/Home Page Hero Banner element - Mobile.png";

const JoyofFreshness = () => {
  const navigate = useNavigate();
  return (
    <section className={`${styles["joyfresh-container"]} `} style={{marginLeft:"6vw", marginRight:"5vw",}}>
      <div className={`${styles["content-div"]}`} >
        {/* <h1 className={styles["custom-h1"]}>Joy of Freshness</h1>
        <p className={styles["custom-p"]}>in each sip</p> */}
        
        <h1 className={styles["custom-h1"]}>Joy of Freshness</h1>
        <p className={styles["custom-p"]}>in each sip</p>
      
        <button
          className={styles["custom-btn"]}
          onClick={() => navigate("/menu")}
        >
          {" "}
          Explore{" "}
        </button>
      </div>
      <div className={styles["image"]}>
        <img
          src={HeroBanner}
          alt="FreshOrangeJuice"
        />
      </div>
      <div className={styles["heroMobile"]}>
        <img
          src={HeroBannerMobile }
          alt="FreshOrangeJuice"
        />
      </div>
    </section>
  );
};

export default JoyofFreshness;

import img1 from "../assets/images/NutritionPage/Healthy Drinks image.png";
import img2 from "../assets/images/NutritionPage/Orchard Promise image.png";
import img3 from "../assets/images/NutritionPage/Honest Fruits image.png";

const OurPhilosophyData = [
  {
    id: 1,
    img: `${img1}`,
    text: "Healthy Drinks",
    description: `When it comes to our drinks, we believe in keeping it real. That's why
        you won't find any artificial sweeteners, preservatives, flavourings,
        or colourings in our bottles. Just pure, unadulterated goodness from
        Mother Nature herself`,
  },
  {
    id: 2,
    img: `${img2}`,
    text: "The Orchard Promise",
    description: `No Added Sugar: Sweetness without the guilt? You bet. We never add sugar to our drinks. The only sweetness you'll taste comes straight from the natural goodness of fruits. `,
  },
  {
    id: 3,
    img: `${img3}`,
    text: "Honest Fruites",
    description: `All Our Smoothies Are a Source of Fiber:We're firm believers in keeping things wholesome, which is why we
        strive to make all our smoothies from whole crushed fruits. That way,
        you get all the fibre-packed goodness you need in every sip. Because
        let's face it, who couldn't use a little extra fibre in their life?`,
  },
];
export { OurPhilosophyData };

import React from "react";
import PureJoySection from "./Pure Joy Part/PureJoyPart";
import JoyofFreshness from "./JoyofFreshness part/JoyofFreshness";
import BlogAndOurStoryImg from "./BlogAndOurStoryImg/BlogAndOurStoryImg";
import HyderabadJuicierPart from "./HyderabadJuicier part/HyderabadJuicierPart";
import BottleSection from "../MenuPage/BottlesSection/BottlesSection";
const HomePage = () => {
  return (
    <div>
      <JoyofFreshness />
      <PureJoySection />
      <BottleSection />
      <BlogAndOurStoryImg />
      <HyderabadJuicierPart />
    </div>
  );
};

export default HomePage;

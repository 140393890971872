import React from "react";
import styles from "./NutritionAndWave.module.scss";
// import appleInHandImg from "../../../assets/images/NutritionPage/NutritionApplepicture1.png";
import appleInHandImg from "../../../assets/images/OurstoryPage Images/Nutrition Apple picture.png";
import Wave from "react-wavify";

const NutritionAndWave = () => {
  return (
    <>
      <div className={styles["Alignment"]}>
        <h2>Nutrition</h2>
        <img
          className={styles["AppleImg"]}
          src={appleInHandImg}
          alt="Holding apple in hand"
        />

        <Wave
          fill="var(--wave-fill-Green)"
          paused={false}
          className={styles["waveUp"]}
          // style={{
          //   display: "flex",
          //   height: "450px",
          //   marginTop: "-160px",
          // }}
          options={{
            height: 10,
            amplitude: 10,
            speed: 0.15,
            points: 3,
          }}
        />
        <Wave
          fill="var(--wave-fill-Green)"
          paused={false}
          className={styles["waveDown"]}
          // style={{
          //   display: "flex",
          //   height: "90px",
          //   transform: "rotate(180deg)",
          // }}
          options={{
            height: 10,
            amplitude: 10,
            speed: 0.15,
            points: 3,
          }}
        />
        {/* </div> */}
        <div className={styles["story-body-1"]}>
          <p className={styles["para1"]}>
            Keeping up with your health can feel like a never-ending maze,
            especially with temptations like ice cream and cozy couches tempting
            you at every turn. But fear not, because we've been on a mission to
            squeeze fruits into bottles to make your journey to wellness a bit
            smoother
          </p>
          <p className={styles["para2"]}>
            While the importance of fruits is well-known, it seems like many of
            us are falling short on our daily intake. But worry not, because
            we're here to shake things up.
          </p>
          {/* <div className={styles["story-body-2-main"]}> */}
          <h3 className={styles["para3"]}>Our mission</h3>
          <p className={styles["para4"]}>
          To craft drinks that not only tantalize your taste buds but also
          pack a punch of goodness,so you can sip your way to a healthier you wherever you go.
          After all, what's better than enjoying a refreshing smoothie to
          brighten your day?
          </p>
          
          {/* <p>
            To craft drinks that not only tantalize your taste buds but also
            pack a punch of goodness,{" "}
          </p>
          <p> so you can sip your way to a healthier you wherever you go. </p>
          <p>
            {" "}
            After all, what's better than enjoying a refreshing smoothie to
            brighten your day?
          </p> */}
        </div>
      </div>
      {/* </div> */}
      {/* </div> */}
    </>
  );
};
export default NutritionAndWave;

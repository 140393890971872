import React from 'react';
import styles from './FormSection.module.css';
import saveIcon from '../../../assets/Logo/save.png';
import attachIcon from '../../../assets/Logo/attach.png';
import signIcon from '../../../assets/Logo/signature.png';
import responseIcon from '../../../assets/Logo/response.png';
import sendIcon from '../../../assets/Logo/send.png';


const FormSection = () => {
  const applyFormatting = (command, value = null) => {
    document.execCommand(command, false, value);
  };


  return (
    <>
      <div className={styles.navbar}>
        <div className={styles.navItem}>
          <img src={saveIcon} alt="Save Icon" className={styles.icon} />
          Save
        </div>
        <div className={styles.navItem}>
          <img src={attachIcon} alt="Attach Icon" className={styles.icon} />
          Attach
        </div>
        <div className={styles.navItem}>
          <img src={signIcon} alt="Signature Icon" className={styles.icon} />
          Signature
        </div>
        <div className={styles.navItem}>
          <img src={responseIcon} alt="Responses Icon" className={styles.icon} />
          Responses
        </div>
      </div>

      <div className={styles.emailSection}>
      <div className={styles.emailHeader}>
        <div className={styles.inputField}>
          <label htmlFor="from">From:</label>
          <input type="text" id="from" placeholder="Your email" />
        </div>
        <div className={styles.inputField}>
          <label htmlFor="to">To:</label>
          <input type="text" id="to" placeholder="Recipient's email" />
        </div>
        <div className={styles.inputField}>
          <label htmlFor="subject">Subject:</label>
          <input type="text" id="subject" placeholder="Email subject" />
        </div>
      </div>
      <div className={styles.toolbar}>
      <button onClick={() => applyFormatting('bold')}><strong>B</strong></button>
        <button onClick={() => applyFormatting('italic')}><em>I</em></button>
        <button onClick={() => applyFormatting('underline')}><u>U</u></button>
        <select onChange={(e) => applyFormatting('formatBlock', e.target.value)}>
          <option value="p">Paragraph</option>
          <option value="h1">Heading 1</option>
          <option value="h2">Heading 2</option>
      
        </select>
        <input type="color" onChange={(e) => applyFormatting('foreColor', e.target.value)} />
       
      </div>
      <div className={styles.messageBox} contentEditable="true" placeholder="Write your message...">
      
      </div>
      <button className={styles.sendButton}>
    <img src={sendIcon} alt="Send Icon" className={styles.sendIcon} />
    Send
  </button>
    </div>
    </>
  );
};

export default FormSection;

import React, { useState, useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import styles from "./CarouselSection.module.scss";
import left from "../../../assets/images/Menu/slider previous button.png";
import right from "../../../assets/images/Menu/slider next button.png";
import carouselData from "../../../data/MenuPageCarouselData.json";

const carouselImages = {
  ClassicColdpressed: require("../../../assets/images/Menu/Carousel Images/Classic Cold pressed slide-Final.jpg"),
  FusionColdpressed: require("../../../assets/images/Menu/Carousel Images/Fusion Coldpressed Slide-Final.jpg"),
  Smoothies: require("../../../assets/images/Menu/Carousel Images/Smoothies Slide-Final.jpg"),
  Milkshakes: require("../../../assets/images/Menu/Carousel Images/Milk Shakes slide-Final.jpg"),
  CutFruitBowls: require("../../../assets/images/Menu/Carousel Images/Cut Fruit Bowl slide-Final.jpg"),
  SmoothieBowls: require("../../../assets/images/Menu/Carousel Images/Smoothie Bowls Slide-Final.jpg"),
};

const CarouselSection = ({
  selectedNavItem,
  setSelectedNavItem,
  carouselImageIndex,
  setCarouselImageIndex,
}) => {
  const arrowRef = useRef();
  const location = useLocation();
  // const [carouselImageIndex, setCarouselImageIndex] = useState(0);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    if (
      [
        "#classic-coldpressed",
        "#fusion-coldpressed",
        "#smoothies",
        "#milkshakes",
        "#cut-fruit-bowls",
        "#smoothie-bowls",
      ].includes(location.hash)
    ) {
      arrowRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [location]);

  const handlePrevClick = () => {
    setCarouselImageIndex((prevIndex) =>
      prevIndex === 0 ? carouselData.length - 1 : prevIndex - 1
    );

    setSelectedNavItem(
      carouselData[
        carouselImageIndex === 0
          ? carouselData.length - 1
          : carouselImageIndex - 1
      ].id.trim()
    );
  };

  const handleNextClick = () => {
    setCarouselImageIndex((prevIndex) => (prevIndex + 1) % carouselData.length);

    setSelectedNavItem(
      carouselData[(carouselImageIndex + 1) % carouselData.length].id.trim()
    );
  };

  const carouselImagePath =
    carouselImages[carouselData[carouselImageIndex].id.trim()];

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div className={styles["body-content-2"]}>
      <div className={styles["body-content-2-main"]}>
        <img
          src={carouselImagePath}
          className={styles["corouselstyles"]}
          alt={carouselData[carouselImageIndex].id.trim()}
        />
        <button
          className="carousel-control-prev corouselbutton btn-sm"
          type="button"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={handlePrevClick}
        >
          <img
            src={left}
            className={styles["button-Previous"]}
            alt="Previous"
          />
        </button>
        <button
          className="carousel-control-next corouselbutton btn-sm"
          type="button"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={handleNextClick}
        >
          <img
            ref={arrowRef}
            src={right}
            className={styles["button-Next"]}
            alt="Next"
          />
        </button>
      </div>
      <div className={styles["corouselText"]}>
        <p className={isHovered ? styles["fade-out"] : styles["fade-in"]}>
          {carouselData[carouselImageIndex].oneLiner}
        </p>
      </div>
    </div>
  );
};

export default CarouselSection;

import img1 from "../assets/images/Menu/Classic Coldpressed/Just Pineapple.png";
import img4 from "../assets/images/Menu/Classic Coldpressed/Just Watermelon.png";
import img3 from "../assets/images/Menu/Classic Coldpressed/Just Muskmelon.png";
import img2 from "../assets/images/Menu/Classic Coldpressed/Just Pomegrenate.png";

import BlogImage from "../assets/images/Home/ExploreFreshness Images/Blog Image.png";
import StoryImage from "../assets/images/Home/ExploreFreshness Images/Our Story Image.png";

const FreshnessThisWayData = [
  {
    id: 1,
    img: `${img1}`,
    text: "Classic Pineapple",
  },
  {
    id: 2,
    img: `${img2}`,
    text: "Classic PomeGranate",
  },
  {
    id: 3,
    img: `${img3}`,
    text: "Classic Muskmelon",
  },
  {
    id: 2,
    img: `${img4}`,
    text: "Classic Watermelon",
  },
];

const FreshnessPartBlogOurStoryData = [
  {
    id: 1,
    img: `${BlogImage}`,
    text: "Blog",
    link: "/blog",
  },
  {
    id: 2,
    img: `${StoryImage}`,
    text: "Our Story",
    link: "/our-story",
  },
];

export { FreshnessThisWayData, FreshnessPartBlogOurStoryData };

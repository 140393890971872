import React, { useState, useEffect } from "react";
import styles from "./ProductDetails.module.css";
import { ProductDetailsData } from "../../data/ProductDetailsData";
import { FaWindowClose } from "react-icons/fa";

const ProductDetails = ({ selectedItem }) => {
  // Find the selected product based on the selectedItem
  const selectedProduct = ProductDetailsData.find(
    (product) => product.id === selectedItem
  );
  const [onClose, setOnClose] = useState(true);

  // Use useEffect to update the onClose state when selectedItem changes
  useEffect(() => {
    // When selectedItem changes, reset onClose to true to ensure the product details are shown
    setOnClose(true);
  }, [selectedItem]);

  const handleOnClose = () => {
    // Toggle onClose state to hide or show the product details
    setOnClose(!onClose);
  };

  return (
    <>
      {selectedItem && onClose && (
        // show &&
        <div className={styles.ProductMain}>
          <div className={styles.closeButton} onClick={handleOnClose}>
            <FaWindowClose   />
          </div>
          <div className={styles.ProductSubMain}>
            <div key={selectedProduct.id}>
              <div className={styles.ProductMargi}>
                <h4>about</h4>
                <div className={styles.ProductMargin}>
                  <li>{selectedProduct.about}</li>
                </div>
              </div>
            </div>
            <div className={styles.ProductAlign}>
              <div className={styles.ProductText2}>
                <li>Ingredients</li>
                <li>{selectedProduct.ingredients}</li>
                <li className={styles.ProductLine}></li>
                <li>nutrition</li>
              </div>

              <li className={styles.ProductTextTwo}>Per 100 ml</li>

              <div className={styles.ProductText}>
                <ul>
                  {Object.keys(selectedProduct.nutrition).map((key) => (
                    <li key={key} className={styles.ProductColor}>
                      <span className={styles.NutritionKey}>{key}</span>
                      <span className={styles.NutritionValue}>
                        {selectedProduct.nutrition[key]}
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ProductDetails;

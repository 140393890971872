import React from "react";
import OurStoryBody1 from "./OurStoryBody1/OurStoryBody1";
import OurStoryBody2 from "./OurStoryBody2/OurStoryBody2";
import NutritionPart from "../WhereToBuy Page/NutritionPart/NutritionPart";

const OurStoryPage = () => {
  return (
    <div>
      <OurStoryBody1 />
      <OurStoryBody2 />
      <NutritionPart />
    </div>
  );
};

export default OurStoryPage;

import React, { useState } from "react";
import styles from "./BottlesSection.module.css";
import { Col, Container, Row } from "react-bootstrap";
import ProductDetails from "../../ProductDetails/ProductDetails"; // Import the ProductDetails component
import { FreshnessThisWayData } from "../../../data/FreshnessThisWay";

const BottlesSection = () => {
  const [selectedItem, setSelectedItem] = useState(null); // State to track selected item

  const handleItemClick = (id, event) => {
    event.stopPropagation(); // Prevent the container's onClick event from firing
    setSelectedItem(id);
  };
  const handleContainerClick = () => {
    setSelectedItem(null);
  };
  return (
    <>
      <Container
        fluid
        className={styles["main-div"]}
        onClick={handleContainerClick}
      >
        <Row lg={4} md={2} xs={2}>
          {FreshnessThisWayData.map(({ img, id, text }) => (
            // <div className={styles.subdiv}>
            <Col
              className={`${styles["subdiv"]} text-center  mb-md-0`}
              key={id}
            >
              <img
                src={img}
                className={styles["image-bottle"]}
                onClick={(event) => handleItemClick(id, event)} // Handle image click
                alt={text}
              />
              <li
                type="button"
                btn-small
                onClick={(event) => handleItemClick(id, event)} // Keep list item click
                className={`${styles["image-headingtop"]} mx-auto`}
              >
                <h5 className={styles["bottle-heading"]}>{text}</h5>
              </li>
            </Col>
            // </div>
          ))}
        </Row>
      </Container>
      {/* Use ProductDetails component */}

      <ProductDetails selectedItem={selectedItem} className=" mb-5" />
    </>
  );
};

export default BottlesSection;

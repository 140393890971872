import Container from "react-bootstrap/Container";
import React, { useState } from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link } from "react-router-dom";
import Logo from "../../assets/Logo/Happy sips logo Primary2.png";
import styles from "./NavbarMain.module.css";
import IconProfile from "../../assets/Logo/IconProfile.jpeg";
import "bootstrap/dist/css/bootstrap.min.css";

function NavbarMain() {
  const [showDropdown, setShowDropdown] = useState(false);
  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };
  return (
    <Navbar expand="lg" className={`${styles["nav_main"]} `}>
      <Container
        fluid
        className={`${styles["nav_container"]} d-flex justify-content-between align-items-center `}
      >
        <div
          className={`${styles["img_cont"]} d-flex justify-content-center align-items-center`}
        >
          <Navbar.Brand>
            <Link to="/">
              <img
                src={Logo}
                alt="Happy sips Logo"
                className={`${styles["nav_img"]} d-flex justify-content-center align-items-center`}
                // alt="Happy sips Logo"
              />
            </Link>
          </Navbar.Brand>
        </div>
        <div>
          <Navbar.Toggle
            className={styles["toggleicon1"]}
            aria-controls="basic-navbar-nav btn-danger"
          />
          <Navbar.Collapse
            className={`${styles["toggleicon"]} bg-white`}
            id="basic-navbar-nav"
          >
            <Nav className={`${styles["navbar-nav"]}`}>
              <Link
                to="/menu"
                className={`${styles["nav_Links"]} ${styles["act1"]}`}
              >
                Menu
              </Link>
              <Link to="/where-to-buy" className={styles["nav_Links"]}>
                Where to Buy
              </Link>
              <Link to="/our-story" className={styles["nav_Links"]}>
                Our Story
              </Link>
            </Nav>
          </Navbar.Collapse>
        </div>
      </Container>
    </Navbar>
  );
}

export default NavbarMain;

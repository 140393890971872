import React from "react";
import NeedSipOfJoy from "./NeedSipOfJoy/NeedSipOfJoy";
import WavePart from "./WavePart/WavePart";
import NutritionPart from "./NutritionPart/NutritionPart";

const WhereToBuyPage = () => {
  return (
    <>
      <NeedSipOfJoy />
      <WavePart />
      <NutritionPart />
    </>
  );
};

export default WhereToBuyPage;

import React from "react";
import styles from "./NutritionPart.module.css";
import appleInHandImg from "../../../assets/images/OurstoryPage Images/NutritionApplepicture.jpg";
import { useNavigate } from "react-router-dom";
import scrollToTop from "../../../components/Footer/scrollToTop";

const NutritionPart = () => {
  const navigate = useNavigate();
  return (
    <div className={`d-flex ${styles["Nutri"]}`}>
      <div>
        <img
          src={appleInHandImg}
          className={styles.appleHand}
          alt="Fresh apple in hand"
        />
      </div>
      <div className={styles.NutritionSide}>
        <p className={` pb-4 ${styles["Font-div2"]}`}>Nutrition</p>

        <p className={styles.note}>
           What's special about our drinks and why they're good for you.
        </p>

        <button
          onClick={() => {
            navigate("/nutrition-page");
            scrollToTop();
          }}
          className={`btn ${styles["Nutri-1"]}`}
        >
          Know More
        </button>
      </div>
    </div>
  );
};

export default NutritionPart;

import React from "react";
import styles from "./OurPhilosophy.module.css";
import { OurPhilosophyData } from "../../../data/OurPhilosophyData";
import { Row, Col } from "react-bootstrap";

const OurPhilosophy = () => {
  return (
    <>
      <h2 className={styles.ourText}>Our Philosophy</h2>
      <div>
        <center>
          <p className={styles.ourTextone}>
            Our philosophy might not be as deep as a philosophical debate, but
            it's what keeps our drinks refreshingly simple, delightfully
            honest,and bursting with goodness - just the way they've always
            been.
          </p>
        </center>
      </div>
      <div className="container-fluid">
        <div className={styles.wrapper}>
          <Row lg={3} md={1} xs={1}>
            {OurPhilosophyData.map(({ img, id, text, description }) => (
              <Col key={id}>
                <img className={styles.ourImages} src={img} alt="Drinks Bottles"/>
                <h5 className={styles.ourfont}>{text}</h5>
                {/* <div className={styles.ourfontmain}> */}
                {/* <li className={styles.ourTextdescription}>{description}</li> */}
                <div className={styles.descText}>
                  {id === 2 && (
                    <p>
                      <strong className={styles.strongDescription}>No Added Sugar:</strong>{" "}
                      {description.split("No Added Sugar:")[1]}
                    </p>
                  )}
                  {id === 3 && (
                    <p>
                      <strong className={styles.strongDescription}>All Our Smoothies Are a Source of Fiber:</strong>{" "}
                      {
                        description.split(
                          "All Our Smoothies Are a Source of Fiber:"
                        )[1]
                      }
                    </p>
                  )}
                  {id !== 2 && id !== 3 && <p className={styles.paraDescription}>{description}</p>}
                </div>
              </Col>
            ))}
          </Row>
        </div>
      </div>
    </>
  );
};

export default OurPhilosophy;

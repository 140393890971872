import React from "react";
import styles from "./HeaderSection.module.scss";

const HeaderSection = () => {
  return (
    <>
      <h2 className={styles["head-1"]}>happy sips</h2>

      <p className={styles["head-2"]}> pure joy in each sip</p>
    </>
  );
};

export default HeaderSection;
